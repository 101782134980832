
html,
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

body {
  min-height: 100%;
  font-family: Roboto, serif;
  background: url('back-r.jpg') no-repeat center center fixed;
  background-size: cover;
}

ul {
  padding-inline-start: 0;
}

ul.hits {
  overflow:scroll;
}
.scrollbar {
  margin-left: 30px;
  float: left;
  max-height: 500px;
  background: #fff;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4285F4; }

